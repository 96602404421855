import {Formik} from 'formik';
import Form from 'react-bootstrap/Form';
import {createRoot} from 'react-dom/client';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from "react-select";
import {ErrorMessage} from "formik";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React, {useState,useEffect} from 'react';
import {profilOptions} from "./profilOptions";
import { useContactForm} from "./onSubmit";
import {createCustomStyles} from "./createCustomStyles";
import {useProgramSelectOptions} from "../../../../page/Programme/component/getProgramSelectOptions";

const HomeContactFormComponent = () => {

    const { handleRecaptchaChange, onSubmit,initialValues,validationSchema } = useContactForm();
    const {optionsProgramme } = useProgramSelectOptions();

    const customStyles = createCustomStyles({
        fontSize: '0.9rem',        // Taille de police
        controlHeight: '45px',   // Hauteur des contrôles
    });

    useEffect(() => {

    }, [optionsProgramme]);

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, formikHelpers) => {
                    // Passer les valeurs, l'état isVerified, et les helpers (comme setSubmitting)
                    onSubmit(values, formikHelpers);
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      isValid,
                      isSubmitting,
                      touched,
                      setFieldValue,
                      handleBlur, // handler for onBlur event of form elements
                  }) => {

                    return (

                    <Form noValidate onSubmit={handleSubmit}>

                                <Row>
                                    <Col md={6}>
                                    <Form.Group className="mb-3">
                                    <Form.Control
                                        onChange={handleChange}
                                        id="nom"
                                        name="nom"
                                        placeholder="Nom"
                                    />
                                </Form.Group>
                                    </Col>
                                        <Col md={6}>
                                    <Form.Group>
                                        <Form.Control
                                            onChange={handleChange}
                                            id="prenom"
                                            name="prenom"
                                            placeholder="Prénom"
                                            type="text"
                                            className="mb-3"
                                        />
                                        <ErrorMessage name={`prenom`} component="div" className="error mb-2"/>
                                    </Form.Group>
                                        </Col>
                                        </Row>
                                <Row>
                                    <Col md={6}>
                                <Form.Group className="mb-3">

                                    <Form.Control
                                    onChange={handleChange}
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    type="email"
                                    className="mb-3"
                                />
                                <ErrorMessage name={`email`} component="div" className="error mb-2"/>
                            </Form.Group></Col>
                                        <Col md={6}>
                                <Form.Group className="mb-3">

                                    <Form.Control
                                        onChange={handleChange}
                                        id="phone"
                                        name="phone"
                                        placeholder="Téléphone"
                                        type="text"
                                    />
                                    <ErrorMessage name={`phone`} component="div" className="error mb-2"/>

                                </Form.Group></Col>
                                </Row>
                        <Row>
                        <Col md={6}>
                                <Select
                                    className={'mb-3'}
                                    defaultValue={{label: "Je suis un particulier", value: '0'}}
                                    styles={customStyles} // Appliquer les styles personnalisés
                                    key={`profil`}
                                    id={`profil`}
                                    options={profilOptions}
                                    name={`profil`}
                                    onChange={(value) => {
                                        setFieldValue('profil', value, false);
                                    }}
                                    // className={'mb-4'}
                                />
                                <ErrorMessage name={`profil`} component="div" className="error mb-2"/>
                        </Col>
                        <Col md={6}>
                            <Select
                                className={'mb-3'}
                                defaultValue={{label: "Sélectionner un programme", value: '0'}}
                                styles={customStyles} // Appliquer les styles personnalisés
                                key={`programme`}
                                id={`programme`}
                                options={optionsProgramme}
                                name={`programme`}
                                onChange={(value) => {
                                    setFieldValue('programme', value, false);
                                }}
                                // className={'mb-4'}
                            />
                            <ErrorMessage name={`programme`} component="div" className="error mb-2"/>

                        </Col>    </Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                // id="comment"
                                as="textarea"
                                rows={3}
                                name="comment"
                                onChange={handleChange}
                                placeholder="Message"
                                className="mb-2"
                            />
                                    <ErrorMessage name={`comment`} component="div" className="error mb-2"/>
                                </Form.Group>
                                <div style={{transform: "scale(0.85)", transformOrigin: "0 0"}}>

                                    <ReCAPTCHA
                                        className={"w-100 g-recaptcha"}
                                        sitekey="6Lca4ncpAAAAAKJVtAjxE5j_Q_iQ-UddoOeE3F5Z"
                                        onChange={handleRecaptchaChange}
                                    />
                                </div>
                            <button
                                disabled={isSubmitting}
                                type="submit" className={"btn bg-japanese-indigo text-white mt-3 w-100"}>Envoyez votre message
                            </button>
                                </Col>

                    </Form>)}}
            </Formik>

        </div>
    );
};

export default function renderHomeContactFormComponent(elementId, props = {}) {
    const container = document.getElementById(elementId);

    if (container) {
        const root = createRoot(container);
        root.render(<HomeContactFormComponent/>);
    }
}
