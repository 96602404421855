import React, {useState} from 'react';
import { Formik, Field, Form } from 'formik';
import {createRoot} from 'react-dom/client';
import Card from 'react-bootstrap/Card';
import Select from "react-select";
import {ErrorMessage} from "formik";
import ReCAPTCHA from 'react-google-recaptcha';
import {useParams} from "react-router-dom";
import {profilOptions} from "./profilOptions";
import {createCustomStyles} from "./createCustomStyles";
import {useContactForm} from "./onSubmit";
import {useProgramSelectOptions} from "../../../../page/Programme/component/getProgramSelectOptions";

const SidebarContactFormComponent = ({programId,programLabel}) => {

    const [id, setId] = useState(useParams().id)
    const { handleRecaptchaChange, onSubmit,initialValues,validationSchema } = useContactForm();
    const {optionsProgramme } = useProgramSelectOptions();
    const customStyles = createCustomStyles({
        fontSize: '13px',        // Taille de police
        controlHeight: '40px',   // Hauteur des contrôles
    });



    return (
        <Card className={"form-contact"}>


                <h3 className="mb-4 mt-4">
                    Contactez-nous</h3>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting,setFieldValue }) => (
                <Form>
                    <label htmlFor="nom">Nom</label>
                    <Field id="nom" name="nom" className="mb-3" placeholder="Entrez votre nom" />
                    <label htmlFor="nom">Prénom</label>
                    <Field id="nom" name="prenom" className="mb-3" placeholder="Entrez votre prénom" />
                    <label htmlFor="email">Email</label>
                    <Field
                        id="email"
                        name="email"
                        placeholder="Entrez votre email"
                        type="email"
                        className="mb-3"
                    />
                    <ErrorMessage name={`email`} component="div" className="error mb-2"/>
                    <label htmlFor="email" className={"mb-2"}>Message</label>
                    <Field
                        as="textarea"
                        id="comment"
                        name="comment"
                        rows="4"
                        style={{ width: '100%' }} // Prendre toute la largeur
                        placeholder="Entrez un message"
                        className="mb-2"
                    />

                    <label htmlFor="email">Vous êtes :</label>
                    <Select
                        defaultValue={{label: "Je suis un particulier", value: '0'}}
                        styles={customStyles} // Appliquer les styles personnalisés
                        key={`profil`}
                        options={profilOptions}
                        name={`profil`}
                        onChange={(value) => {
                            setFieldValue('profil', value, false);
                        }}
                       className={'mb-3'}
                    />
                    <ErrorMessage name={`profil`} component="div" className="error mb-2"/>
                    <label htmlFor="email">Intéressé par :</label>
                    <Select
                        className={'mb-1'}
                        defaultValue={{label: programLabel, value: programId}}
                        styles={customStyles} // Appliquer les styles personnalisés
                        key={`programme`}
                        id={`programme`}
                        options={optionsProgramme}
                        name={`programme`}
                        onChange={(value) => {
                            setFieldValue('programme', value, false);
                        }}
                        // className={'mb-4'}
                    />
                    <ErrorMessage name={`programme`} component="div" className="error mb-2"/>
                    <label className={"mt-3"}>Téléphone</label>
                    <Field
                        id="phone"
                        name="phone"
                        placeholder="Entrez un numéro de téléphone"
                        className="mb-2"
                    />
                    <ErrorMessage name={`phone`} component="div" className="error mb-2"/>
                    <div style={{transform: "scale(0.85)", transformOrigin: "0 0"}} className={'mt-2'}>

                        <ReCAPTCHA
                            className={"w-100 g-recaptcha"}
                            sitekey="6Lca4ncpAAAAAKJVtAjxE5j_Q_iQ-UddoOeE3F5Z"
                            onChange={handleRecaptchaChange}
                        />
                    </div>
                    <button
                        disabled={isSubmitting}
                        type="submit" className={"btn bg-beer text-white mt-4 w-100"}>Envoyez votre message</button>
                </Form>  )}
            </Formik>

        </Card>
    );
};

export default function renderSidebarContactFormComponent(elementId, props = {}) {
    const container = document.getElementById(elementId);

    if (container) {
        const root = createRoot(container);
        root.render(<SidebarContactFormComponent programId={props.programId} programLabel={props.programLabel}/>);
    }
}
