
import {createRoot} from 'react-dom/client';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from "@react-google-maps/api";
import React, {useState,useEffect} from 'react';
import axios from "axios";

const containerStyle = {
    width: "100%",
    height: "500px",
};

const center = {
    lat: 49.133333, // Latitude de Metz
    lng: 6.166667,  // Longitude de Metz
};


const ProgramsMapComponent = () => {

    const [markers, setMarkers] = useState([]);

    const getProgrammeList = () => {
        axios.get(`/front/options/programmes`)
            .then(function (response) {

                setMarkers(response.data)
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    text:'getProgrammeList :'+error,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
    }

    useEffect(() => {
        getProgrammeList();
    }, []);

    // Fonction pour créer une icône circulaire à partir d'une image
    const createCircularIcon = async (imageUrl) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous"; // Important pour éviter les problèmes CORS
            img.src = imageUrl;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                const size = 50; // Taille de l'icône
                canvas.width = size;
                canvas.height = size;

                // Dessine un cercle blanc en arrière-plan
                ctx.beginPath();
                ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
                ctx.closePath();
                ctx.fillStyle = "#fff"; // Bordure blanche
                ctx.fill();

                // Dessine l'image à l'intérieur du cercle
                ctx.save();
                ctx.beginPath();
                ctx.arc(size / 2, size / 2, size / 2 - 3, 0, Math.PI * 2);
                ctx.closePath();
                ctx.clip();
                ctx.drawImage(img, 0, 0, size, size);
                ctx.restore();

                // Convertit le canvas en Data URL
                resolve(canvas.toDataURL());
            };

            img.onerror = () => reject(new Error(`Impossible de charger l'image : ${imageUrl}`));
        });
    };

    const [markerIcons, setMarkerIcons] = useState({});
    useEffect(() => {
        if (markers.length === 0) return;

        // Fonction asynchrone pour charger les icônes
        const loadIcons = async () => {
            const icons = {};

            for (const marker of markers) {
                try {
                    const icon = await createCircularIcon(marker.thumbnail);
                    icons[marker.id] = icon;
                } catch (error) {
                    console.error(`Erreur lors de la création de l'icône pour le marqueur ${marker.id}`, error);
                }
            }

            setMarkerIcons(icons); // Met à jour l'état avec les nouvelles icônes
        };

        loadIcons();
    }, [markers]);

    useEffect(() => {

    }, [markerIcons]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBfJxYeGMtBnel2Sxu-bxRIfxKCukE_KuY", // Remplacez par votre clé API
    });

    if (!isLoaded) return <p>Chargement de la carte...</p>;

    return (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={8}>
            {markers.map((marker) => (

                <Marker
                    key={marker.id}
                    position={marker.position}
                    icon={{
                        url: markerIcons[marker.id] || marker.image, // Utilise l'icône personnalisée
                        scaledSize: new window.google.maps.Size(50, 50), // Taille de l'icône
                        anchor: new window.google.maps.Point(25, 25), // Centre de l'icône
                    }}
                    onClick={() => window.open(marker.link, "_blank")}
                />
            ))}
        </GoogleMap>
    );
};

export default function renderProgramsMapComponent(elementId, props = {}) {
    const container = document.getElementById(elementId);

    if (container) {
        const root = createRoot(container);
        root.render(<ProgramsMapComponent/>);
    }
}
