import axios from 'axios';
import Swal from 'sweetalert2'
import React, {useState,useEffect} from 'react';
import {useContactForm} from "../../../frontend/Client/57006/Component/onSubmit";

export const useProgramSelectOptions = () => {

    const [optionsProgramme, setOptionsProgramme] = useState([]);

    useEffect(() => {
        getOptionsProgramme();
    }, []);

    const getOptionsProgramme = () => {

        axios.get(`/front/options/programmes`)
            .then(function (response) {

                var options = [];
                response.data ? response.data.map((item, index) => {
                    options.push({'value': item.id, 'label': item.label});
                }) : null;

                setOptionsProgramme(options);
            })
            .catch(function (error) {

                Swal.fire({
                    icon: 'error',
                    title: "Une erreur s'est produite!",
                    text: 'getProgrammeList :' + error,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
    }

    return {
        optionsProgramme
    };
}