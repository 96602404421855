require('jquery');
//g�n�re un zoom lors d'un clique sur l'une des images d'un promogramme ou d'un lot
require('bootstrap/dist/js/bootstrap.min.js');
require('bootstrap/dist/css/bootstrap.min.css');
import 'slick-carousel/slick/slick.min.js'; // Importer le JS de Slick
import renderMyComponent from './page/Programme/component/LoanSimulator';
import renderMapComponent from './page/Programme/component/MapComponent';
import renderSidebarContactFormComponent from './frontend/Client/57006/Component/SidebarContactFormComponent';
import renderHomeContactFormComponent from './frontend/Client/57006/Component/HomeContactFormComponent';
import renderProgramsMapComponent from './frontend/Client/57006/Component/ProgramsMapComponent';
window.renderProgramsMapComponent = renderProgramsMapComponent;
window.renderMyComponent = renderMyComponent;
window.renderMapComponent = renderMapComponent;
window.renderSidebarContactFormComponent = renderSidebarContactFormComponent;
window.renderHomeContactFormComponent = renderHomeContactFormComponent;
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.$ = require("jquery");

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
import fontawesome from "@fortawesome/fontawesome";
import faUser from '@fortawesome/fontawesome-free-solid/faUser'
import faUserPlus from '@fortawesome/fontawesome-free-solid/faUserPlus';
import faTachometerAlt from "@fortawesome/fontawesome-free-solid/faTachometerAlt";
import faSearch from "@fortawesome/fontawesome-free-solid/faSearch";
import faSignOutAlt from "@fortawesome/fontawesome-free-solid/faSignOutAlt";
import faSignInAlt from "@fortawesome/fontawesome-free-solid/faSignInAlt";
import faStar from "@fortawesome/fontawesome-free-solid/faStar";
import faAngleDoubleDown from "@fortawesome/fontawesome-free-solid/faAngleDoubleDown";
import faChevronCircleRight from "@fortawesome/fontawesome-free-solid/faChevronCircleRight";
import faCalculator from "@fortawesome/fontawesome-free-solid/faCalculator";
import faMapMarkerAlt from '@fortawesome/fontawesome-free-solid/faMapMarkerAlt';
import faChevronRight from '@fortawesome/fontawesome-free-solid/faChevronRight';
import faChevronLeft from '@fortawesome/fontawesome-free-solid/faChevronLeft';
import faEnvelope from '@fortawesome/fontawesome-free-solid/faEnvelope';
import faPrint from '@fortawesome/fontawesome-free-solid/faPrint';
import faFilePdf from '@fortawesome/fontawesome-free-solid/faFilePdf';
import faPhone from '@fortawesome/fontawesome-free-solid/faPhone';
import faFacebookSquare from '@fortawesome/fontawesome-free-brands/faFacebookSquare';
import faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter';
import faLaravel from '@fortawesome/fontawesome-free-brands/faLaravel';
import faCheck from '@fortawesome/fontawesome-free-solid/faCheck';
import faCalendar from '@fortawesome/fontawesome-free-regular/faCalendar';
import faSquare from '@fortawesome/fontawesome-free-regular/faSquare';

fontawesome.library.add(faSquare,faCalendar,faChevronCircleRight,faCheck,faLaravel,faPhone,faTwitter,faFacebookSquare,faUser,faUserPlus,faTachometerAlt, faSearch,faSignOutAlt,faSignInAlt,faStar,faAngleDoubleDown,faEnvelope,faCalculator,faPrint,faMapMarkerAlt,faChevronRight,faChevronLeft,faFilePdf);

require.context('../img', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);

// ...rest of JavaScript code here