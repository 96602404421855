import { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import * as Yup from "yup";

export function useContactForm() {
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {

    }, [isVerified]);
    const handleRecaptchaChange = (value) => {

        setIsVerified(true);
    };

    const initialValues = {
        nom: '',
        phone: '',
        comment: '',
        email: '',
        profil:'',
        programme:''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Format de l\'adresse email invalide')
            .required('L\'adresse e-mail est requise'),
        phone: Yup.string()
            .required("Un téléphone est requis"),
    });


    function sendContactFormEmail(fields) {
        axios.post(`/send-message`, fields)
            .then(function (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'Message envoyé!',
                    text: 'Merci pour votre prise de contact ! Notre équipe a bien pris en compte votre demande et vous contactera rapidement.',
                    showConfirmButton: false,
                    timer: 3000
                });

                setTimeout(() => {
                    window.location.reload();
                }, 3000); // Attend 3 secondes avant de recharger la page
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Une erreur s\'est produite!',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
    }



    const onSubmit = (values, { setSubmitting }) => {

        if (!isVerified) {
            Swal.fire({
                icon: 'warning',
                title: "Sécurité anti-robot",
                text: "Veuillez cocher la case anti-robot",
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                setSubmitting(false); // Réinitialise isSubmitting après l'affichage de l'avertissement
            });
        } else {
            values.profil = values.profil ? values.profil.value : null;
            values.programme = values.programme ? values.programme.value : null;
            sendContactFormEmail(values);
        }
    };

    return {
        handleRecaptchaChange,
        onSubmit,
        initialValues,
        validationSchema
    };
}
