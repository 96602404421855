import React, {useState, useEffect} from 'react';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from "@react-google-maps/api";
import {createRoot} from 'react-dom/client';

const MapComponent = ({lat,long,address}) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBfJxYeGMtBnel2Sxu-bxRIfxKCukE_KuY", // Remplacez par votre clé API
    });

;

    // Coordonnées du lieu
    const location = { lat: lat, lng: long }; // Exemple (à remplacer par les coordonnées réelles)

    return (
        <div style={{ width: "100%", height: "400px" }}>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    center={location}
                    zoom={15}
                >
                    <Marker position={location}>
                        <InfoWindow position={location}>
                            <div>
                                <span><strong>{address}</strong></span>

                            </div>
                        </InfoWindow>
                    </Marker>
                </GoogleMap>
            ) : (
                <p>Chargement de la carte...</p>
            )}
        </div>
    );
};

export default function renderMapComponent(elementId, props = {}) {
    const container = document.getElementById(elementId);

    if (container) {
        const root = createRoot(container);
        root.render(<MapComponent lat={props.lat} long={props.long} address={props.address}/>);
    }
}
