// customStyles.js
export const createCustomStyles = (options = {}) => {
    const { fontSize = '13px', controlHeight = '40px' } = options;

    return {
        option: (provided) => ({
            ...provided,
            fontSize: fontSize, // Taille de la police des options
        }),
        control: (provided) => ({
            ...provided,
            minHeight: controlHeight, // Hauteur minimale
            height: controlHeight,    // Hauteur fixe
            fontSize: fontSize,       // Taille de la police
        }),
    };
};
