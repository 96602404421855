import React, {useState} from 'react';
import {createRoot} from 'react-dom/client';
import Slider from '@mui/material/Slider';

const LoanSimulator = ({ message }) => {
    // États pour les sliders
    const [rate, setRate] = useState(2.9); // Taux en pourcentage
    const [loanAmount, setLoanAmount] = useState(320000); // Montant emprunté
    const [duration, setDuration] = useState(208); // Durée en mois

    // Calcul des mensualités
    const calculateMonthlyPayment = () => {
        const monthlyRate = rate / 100 / 12; // Taux mensuel
        return (
            (loanAmount * monthlyRate) /
            (1 - Math.pow(1 + monthlyRate, -duration))
        ).toFixed(2); // Arrondi à 2 décimales
    };

    return (
        <div style={styles.container}>
            <h3 style={styles.title}>Simulateur de prêt</h3>
            <p style={styles.description}>
                Calculez le montant de votre mensualité en fonction de votre budget
            </p>
            <div style={styles.sliderContainer}>
                <label style={styles.label}>Taux : {rate.toFixed(1)} %</label>
                <Slider
                    value={rate}
                    onChange={(e) => setRate(parseFloat(e.target.value))}
                    min={0.1}
                    max={5}
                    step={0.1}      // Incréments de 10
                    valueLabelDisplay="off" // Affiche la valeur actuelle au survol
                    sx={{
                        "& .MuiSlider-thumb": { color: "#3f7065" }, // Customisation du curseur
                        "& .MuiSlider-track": { color: "#3f7065" }, // Customisation de la barre active
                        "& .MuiSlider-rail": { color: "#ccc" }      // Customisation de la barre inactive
                    }}
                />

            </div>
            <div style={styles.sliderContainer}>
                <label style={styles.label}>
                    Emprunt : {loanAmount.toLocaleString()} €
                </label>
                <Slider
                    value={loanAmount}
                    onChange={(e) => setLoanAmount(parseInt(e.target.value))}
                    min={10000}
                    max={1000000}
                    valueLabelDisplay="off" // Affiche la valeur actuelle au survol
                    sx={{
                        "& .MuiSlider-thumb": { color: "#3f7065" }, // Customisation du curseur
                        "& .MuiSlider-track": { color: "#3f7065" }, // Customisation de la barre active
                        "& .MuiSlider-rail": { color: "#ccc" }      // Customisation de la barre inactive
                    }}
                />
            </div>
            <div style={styles.sliderContainer}>
                <label style={styles.label}>Mensualité : {duration} mois</label>
                <Slider
                    value={duration}
                    onChange={(e) => setDuration(parseInt(e.target.value))}
                    min={12}
                    max={360}
                    valueLabelDisplay="off" // Affiche la valeur actuelle au survol
                    sx={{
                        "& .MuiSlider-thumb": { color: "#3f7065" }, // Customisation du curseur
                        "& .MuiSlider-track": { color: "#3f7065" }, // Customisation de la barre active
                        "& .MuiSlider-rail": { color: "#ccc" }      // Customisation de la barre inactive
                    }}
                />
            </div>
            <div style={styles.result}>
                <p className={"text-white"}>Votre mensualité est de :</p>
                <p className={'h2'}>{calculateMonthlyPayment()} €/mois</p>
            </div>
        </div>
    );
};
// Styles CSS en JS
const styles = {
    container: {
        padding: '20px',
        backgroundColor: '#2a4b44',
        color: '#fff',
        borderRadius: '10px',
        fontFamily: 'Arial, sans-serif',
    },
    title: {
        fontSize: '20px',
        color:'#d49618',
        marginBottom: '10px',
        textAlign: 'center',
    },
    description: {
        color:'white',
        fontSize: '12px',
        marginBottom: '20px',
        textAlign: 'center',
    },
    sliderContainer: {
        marginBottom: '20px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontSize: '14px',
    },
    slider: {
        width: '100%',
    },

    result: {
        color:'white',
        textAlign: 'center',
        backgroundColor: '#203a34',
        padding: '10px',
        borderRadius: '5px',
    },

};

// Attacher le composant React à un élément DOM spécifique
export default function renderMyComponent(elementId, props = {}) {
    const container = document.getElementById(elementId);

    if (container) {
        const root = createRoot(container);
        root.render(<LoanSimulator {...props} />);
    }
}